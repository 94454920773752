
// offcanvas menu wrapper

.rts-offcanvas-wrapper{
    .container-menu{
        position: relative;
        z-index: 1;
        z-index: 99;
        .action-menu{
            cursor: pointer;
            position: relative;
            .close-event {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                top: 0;
                left: 0;
                display: none;
                cursor: pointer;
            }
            .open-event {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-flow: row wrap;
                flex-flow: row wrap;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                cursor: pointer;
            }
            .text{
                -webkit-box-flex: 1;
                -ms-flex: 1 1;
                flex: 1 1;
                position: relative;
                font-size: 20px;
                letter-spacing: 1pt;
            }
            .burger{
                width: 28px;
                height: 100%;
                position: relative;
                margin-left: 10px;
                .icon-close{
                    position: absolute;
                    top: -2px;
                    left: 0;
                    will-change: transform;
                    -webkit-transform: rotate(90deg) scale(0.8);
                    transform: rotate(90deg) scale(0.8);
                    -webkit-transition: color 0.5s, opacity 0.5s, -webkit-transform 0.5s;
                    transition: color 0.5s, opacity 0.5s, -webkit-transform 0.5s;
                    transition: color 0.5s, opacity 0.5s, transform 0.5s;
                    transition: color 0.5s, opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                }
                .icon-burger{
                    will-change: transform;
                    -webkit-transition: color 0.5s, -webkit-transform 0.3s;
                    transition: color 0.5s, -webkit-transform 0.3s;
                    transition: transform 0.3s, color 0.5s;
                    transition: transform 0.3s, color 0.5s, -webkit-transform 0.3s;
                    overflow: hidden;
                    color: #fff;
                }
            }
        }
    }
}


.rts-fs-menu {
    background: #0E0E0E;
    background-color: #0E0E0E;
    background-repeat: repeat;
    background-position: center center;
    background-attachment: scroll;
    -ms-background-size: cover;
    background-size: cover;
    position: fixed;
    overscroll-behavior-y: contain;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-clip-path: polygon(0 0, 100% 0, 130% 0, 0 0);
    clip-path: polygon(0 0, 100% 0, 130% 0, 0 0);
    -webkit-transform: translate(0, 0) rotate(7deg) scale(1.3);
    transform: translate(0, 0) rotate(7deg) scale(1.3);
    -webkit-transition: visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);


    // background-image: url(../images/banner/01.jpg);
    .menu-item{
        opacity: 1;
        transition: .3s;
        .sub-menu{
            li{
                padding: 15px 0;
            }
        }
    }
    &.visible{
        opacity: 1;
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
        transform: translate(0, 0) rotate(0) scale(1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        -webkit-transition: visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), opacity 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
        transition: visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), opacity 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
        transition: clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), opacity 1s cubic-bezier(0.575, 0.015, 0, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
        transition: clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), visibility 1s cubic-bezier(0.575, 0.015, 0, 0.995), opacity 1s cubic-bezier(0.575, 0.015, 0, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-clip-path 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    }
    ul.navbar-nav-button{
        display: block;
        list-style: none;
        width: auto;
        position: relative;
        padding-left: 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        margin-top: 0;
        &.active > li{
            overflow: hidden;
            @media(max-width:768px){
                justify-content: center;
            }
        }
    }
    .rts-fs-container{
        position: relative;
        padding: 6rem 0;
        max-width: min(calc(100vw - 8 * 25px), 1240px);
        width: 100%;
        margin: auto auto;
        height: 600px;
        @media(max-width:1024px){
            max-width: min(calc(100vw - 4 * 25px), 1240px);
            padding: 6rem 0 0 0;
        }
        @media(max-width:768px){
            max-width: min(calc(100vw - 0 * 25px), 1240px);
            padding: 6rem 0 0 0;
        }
        .active{
            z-index: 9;
        }
        .rts-fs--nav{
            -webkit-clip-path: inset(-100vw 0 -100vw 0);
            clip-path: inset(-100vw 0 -100vw 0);
        }
    }
}

.visible {
    visibility: visible !important;
}
.navbar-nav-button.active > li {
    overflow: hidden;
    line-height: 26px;
    padding: 15px 0;
    @media(max-width:991px){
        padding: 10px 0;
    }
    &:first-child{
        padding-top: 6px;
    }
}

.rts-fs-menu * .menu-item > a {
    cursor: pointer;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #e8eaed;
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 800;
}
.rts-fs-menu .menu-item-has-children > a span {
    margin-left: 0;
    position: absolute;
    left: 100%;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    opacity: 0;
    width: 32px;
    height: 100%;
    -webkit-transition: all 320ms cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: all 320ms cubic-bezier(0.575, 0.015, 0, 0.995);
}

.rts-fs-menu .menu-item-has-children > a:hover span {
    opacity: 1;
    margin-left: 16pt;
}

.rts-fs-menu .menu-item-has-children > a span svg {
    fill: #fff;
    width: 32px;
    height: 32px;
}
.rts-fs-menu ul.navbar-nav-button * > ul {
    list-style: none;
    padding-left: 0;
    width: 100%;
}
.rts-fs-menu ul.navbar-nav-button > li > ul {
    position: absolute;
    top: 0;
    left: 100%;
}
.menu-item--back {
    text-align: left;
    cursor: pointer;
    font-size: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 4pt;
    letter-spacing: 1pt;
    text-transform: uppercase;
    &::before{
        content: "";
        border: solid #9aa0a6;
        width: 12px;
        height: 12px;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        margin-top: 2pt;
        margin-left: 2pt;
        margin-right: 8pt;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-transition: margin-right 0.3s ease;
        transition: margin-right 0.3s ease;
    }
}
.rts-fs-menu * li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rts-fs-container .sub-menu > .menu-item--back {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
}
.rts-fs-menu.visible .menu-item {
    opacity: 1;
}
.rts-fs-menu * .menu-item > a {
    font-family: var(--font-heading);
    cursor: pointer;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #e8eaed;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 800;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    @media(max-width:991px){
        font-size: 30px;
    }
    @media(max-width:576px){
        font-size: 26px;
    }
}
.rts-fs-container .sub-menu li a{
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
}
.rts-fs-container .sub-menu.active > li > a {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
}
.rts-fs-container .sub-menu.active > .menu-item--back{
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 0;
    a{
        color: #fff;
    }
}


.action-menu .burger .icon-close {
    position: absolute;
    top: -2px;
    left: 0;
    opacity: 0;
    will-change: transform;
    -webkit-transform: rotate(90deg) scale(0.8);
    transform: rotate(90deg) scale(0.8);
    -webkit-transition: color 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    transition: color 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    transition: color 0.5s, opacity 0.5s, transform 0.5s;
    transition: color 0.5s, opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}
.action-menu .burger .icon-burger {
    will-change: transform;
    -webkit-transition: color 0.5s, -webkit-transform 0.3s;
    transition: color 0.5s, -webkit-transform 0.3s;
    transition: transform 0.3s, color 0.5s;
    transition: transform 0.3s, color 0.5s, -webkit-transform 0.3s;
    overflow: hidden;
    color: var(--color-contrast-higher);
}
.open-event.style-open .icon-close {
    color: var(--color-white);
    -webkit-transform: rotate(90deg) scale(1);
    transform: rotate(90deg) scale(1);
    -webkit-transition: color 0.5s, -webkit-transform 0.5s;
    transition: color 0.5s, -webkit-transform 0.5s;
    transition: color 0.5s, transform 0.5s;
    transition: color 0.5s, transform 0.5s, -webkit-transform 0.5s;
    opacity: 1;
}
.open-event.style-open .icon-burger {
    color: var(--color-white);
    opacity: 0;
}


.rts-fs-container .sub-menu .sub-menu.activ{
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
}
.rts-fs-container .sub-menu .sub-menu{
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
    transition: visibility 1s, opacity 0.2s ease-out, color 0.3s ease;
}
.rts-fs-menu ul.navbar-nav-button > li > ul ul {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.rts-fs-menu ul.navbar-nav-button * > ul {
    list-style: none;
    padding-left: 0;
    width: 100%;
}

.rts-fs-container .sub-menu .sub-menu.active{
    opacity: 1;
}





.rts-fs-container{
    opacity: 0;
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: translate(0, -50%) rotate(-16deg) scale(1.3, 1.3);
    transform: translate(0, -50%) rotate(-16deg) scale(1.3, 1.3);
    -webkit-transition: opacity 1s 0s, -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: opacity 1s 0s, -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: transform 1s cubic-bezier(0.575, 0.015, 0, 0.995), opacity 1s 0s;
    transition: transform 1s cubic-bezier(0.575, 0.015, 0, 0.995), opacity 1s 0s, -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);


    position: relative;
    padding: 6rem 0;
    max-width:min(calc(100vw - 8 * 25px), 1240px);
    width: 100%;
    margin: auto auto;

    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    opacity: 1;
    -webkit-transform: translate(0, 0) rotate(0) scale(1);
    transform: translate(0, 0) rotate(0) scale(1);
    -webkit-transition: opacity 1s 0.2s, -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: opacity 1s 0.2s, -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: opacity 1s 0.2s, transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: opacity 1s 0.2s, transform 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
}


.rts-fs--nav{
    -webkit-clip-path: inset(-100vw 0 -100vw 0);
    clip-path: inset(-100vw 0 -100vw 0);
}


.rts-fs--contacts{
    position: relative;
    z-index: 9;
    @media(max-width:768px){
        display: none;
    }
   .contact-inner{
        .contact-information{
            margin-bottom: 35px;
            @media(max-width:768px){
                text-align: center;
            }
            .heading-title{
                font-size: 32px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            ul{
                padding: 0;
                margin: 0;
                li{
                    p,
                    a{
                        color: #bdbdbd;
                    }
                    @media(max-width:768px){
                        justify-content: center;
                    }
                }
            }
            .rts-social-area-one{
                margin-top: 35px;
                a{
                    transition: all .3s;
                    &:hover{
                        background: #FFFFFF;
                        i{
                            color: #1b1b1b;
                        }
                    }
                    i{
                        transition: all .3s;
                    }
                }
            }
        }
   } 
}

.rts-main {
    -webkit-clip-path: none !important;
    clip-path: none !important;
}

.rts-main {
    -webkit-transform-origin: 0;
    transform-origin: 0;


    -webkit-transform-origin: 25vw 0;
    transform-origin: 25vw 0;
    will-change: transform;
    -webkit-transform: translateZ(0) rotate(0) scale(1, 1);
    transform: translateZ(0) rotate(0) scale(1, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: transform 1s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1s cubic-bezier(0.575, 0.015, 0, 0.995);
}

.fsm-opened .rts-main {
    -webkit-transform-origin: 25vw 0;
    transform-origin: 25vw 0;
    -webkit-transform: translate(0, 50vh) rotate(7deg) scale(1.3, 1.3);
    transform: translate(0, 50vh) rotate(7deg) scale(1.3, 1.3);
    -webkit-transition: -webkit-transform 1.2s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: -webkit-transform 1.2s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: transform 1.2s cubic-bezier(0.575, 0.015, 0, 0.995);
    transition: transform 1.2s cubic-bezier(0.575, 0.015, 0, 0.995), -webkit-transform 1.2s cubic-bezier(0.575, 0.015, 0, 0.995);
}

// .index-one{
//     background: #0D0E13;
// }
