.cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 1020px;
    height: 1020px;
    pointer-events: none;
    opacity: 0;
  }
  
  .cursor__media {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .cursor__media.active {
    opacity: 0.8;
  }
  
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.6rem;
  }
  
  .nav__link {
    position: relative;
    color: #1e293b;
    font-size: 2.6rem;
    font-weight: 700;
    text-decoration: none;
  }
  
  .nav__link:not(:last-child)::before {
    content: "";
    position: absolute;
    bottom: -1.3rem;
    left: 0;
    right: 0;
    width: 2rem;
    height: 2px;
    margin-inline: auto;
    background-color: #94a3b8;
  }
  




.title-area-inner.large-image{
  display: flex;
  justify-content: space-between;
  .title{
    text-align: center;
    font-size: 90px;
    margin-bottom: -200px;
    z-index: 10;
    position: relative;
    font-weight: 800;
    text-transform: uppercase;
    // mix-blend-mode: exclusion !important;
    @media #{$laptop-device} {
      font-size: 60px;
    }
    @media #{$smlg-device} {
      font-size: 50px;
    }
    @media #{$md-layout} {
      font-size: 40px;
    }
    @media #{$sm-layout} {
      font-size: 40px;
    }
    @media #{$small-mobile} {
      font-size: 21px;
    }
    span{
      font-size: 120px;
      color: rgb(255, 255, 255);
      @media #{$laptop-device} {
        font-size: 90px;
      }
      @media #{$smlg-device} {
        font-size: 80px;
      }
      @media #{$md-layout} {
        font-size: 50px;
      }
      @media #{$sm-layout} {
        font-size: 40px;
      }
      @media #{$small-mobile} {
        font-size: 21px;
      }
    }
  }
  @media #{$sm-layout} {
    max-width: 100%;
  }
}