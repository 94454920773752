// cta style one

.cta-main-wrapper-one{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 60px;
    background: #111111;
    position: relative;
    z-index: 1;
    border-radius: 8px;
    @media #{$laptop-device} {
        gap: 30px;
    }
    @media #{$smlg-device} {
        padding: 25px;
        gap: 30px;
    }
    @media #{$md-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media #{$large-mobile} {
        padding: 15px;
    }
    .right-bg-image{
        position: absolute;
        right: 160px;
        top:62%;
        transform: translateY(-50%);
        z-index: -1;
        img{
            min-width: max-content;
        }
    }
    .cta-mid-area{
        .title{
            color: #D9D9D9;
            @media #{$smlg-device} {
                font-size: 24px !important;
                text-transform: uppercase;
                line-height: 43px;
            }
            span{
                font-style: italic;
                font-weight: 300;
            }
            br{
                @media #{$smlg-device} {
                    display: none;
                }
            }
        }
        p.disc{
            color: #74787C;
            br{
                @media #{$smlg-device} {
                    display: none;
                }
            }
        }
    }
}

.rts-footer-area-one{
    .grid-lines-wrapper{
        @media #{$extra-device} {
            display: none;
        }
        @media #{$laptop-device} {
            display: none;
        }
        @media #{$smlg-device} {
            display: none;
        }
    }
}


.title-area-inner.large-image{
    .title{
        @media #{$md-layout} {
            font-size: 26px;
            line-height: 38px;
        }
        br{
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
}