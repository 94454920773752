// /* Start loading screen rules */

.loading-screen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: #070707;
    .bar {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #000000;
    }
    .top-bar {
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
    }
    .down-bar {
      bottom: 0;
      top: auto;
      right: 0;
      left: auto;
    }
    .progress-line {
      position: absolute;
      width: 100%;
      top: 60%;
      left: 0;
      right: auto;
      height: 1px;
      background-color: rgba(#ffffff05, 0.35);
      transform: scale(0);
      transform-origin: center;
      opacity: 0;
    }
    .loading-counter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fefeff;
      font-size: 90px;
      font-weight: 700;
    }
}
/* End loading screen rules */
  