
// social area start
.rts-social-area-one{
    ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        gap: 10px;
        @media(max-width:768px){
            justify-content: center;
        }
        li{
            margin: 0;
            a{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #1B1B1B;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .3s;
                i{
                    color: #fff;
                }
            }
            &:hover{
                a{
                    transform: scale(1.2) translateY(-3px);
                }
            }
        }
    }
}