// banner video
.wrapper-video {
    --clip-path: circle(140px at left);
    --clip-path-hover: circle(160px at left);
    --clip-path-clicked: circle(100vw at left);
    --duration: .4s;
    --timing-function: ease;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
    .video-info{
        width: 300px;
        position: relative;
        left: 70%;
        top: -12%;
        height: 200px;
        bottom: auto;
        margin-top: -450px;
        #video-check{
            width: 200px;
            height: 200px;
            margin: auto;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            border-radius: 40px;
            outline: none;
            z-index: 2;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
            z-index: 9999999;
        }
        .intro-title{
            position: relative;
            left: 195px;
            top: 100px;
            .video-title{
                font-size: 16px;
                text-transform: uppercase;
                line-height: 1.3;
                &.close-video-title{
                    display: none;
                }
                span{
                    display: block;
                    font-size: 18px;
                }
            }

        }
        .video{
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-clip-path: var(--clip-path);
            clip-path: var(--clip-path);
            -webkit-transition: -webkit-clip-path .3s ease;
            transition: -webkit-clip-path .3s ease;
            transition: clip-path .3s ease;
            transition: clip-path .3s ease, -webkit-clip-path .3s ease;
            position: relative;
            top: 55px;
            left: 100px;
            video{
                position: fixed;
                top: 0;
                left: 0;
                min-width: 100%;
                min-height: auto;
                width: auto;
                height: auto;
            }
            &::before{
                position: absolute;
                top: -20px;
                left: -20px;
                width: 40px;
                height: 40px;
                background: url(../images/video/play-icon.png);
                content: "";
                z-index: 999999999999;
                background-size: 100%;
            }
        }
    }
}
.banner-style-one{
    overflow: hidden;
}
#video_check{
    width: 200px;
    height: 200px;
    margin: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    border-radius: 40px;
    outline: none;
    z-index: 2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    z-index: 9999999;
}

#video_check:hover ~ .video {
    -webkit-clip-path: var(--clip-path-hover);
    clip-path: var(--clip-path-hover);
}

#video_check:checked ~ .video {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    height: 250px;
    width: 1920px;
    left: 0;
}
// #video_check:checked ~ video {
//     -webkit-clip-path: var(--clip-path-clicked);
//     clip-path: var(--clip-path-clicked);
// }

.banner-jump-button{
    position: absolute;
    left: 17%;
    top: 60%;
    @media #{$sm-layout} {
        left: 60px;
        top: 55%;
    }
    @media #{$small-mobile} {
        left: 10%;
        top: 44%;
    }
    .btn_wrapper{
        @media #{$sm-layout} {
            margin-left: 0 !important;
        }
    }
    .explore-button{
        min-width: max-content;
        max-width: max-content;
        &:hover{
            min-width:190px !important;
        }
    }
}
.title-wrap-cta-4{
    .title{
        line-height: 26px;
        margin: 0;
    }
}

.large-image-option-start{
    position: relative;
    .wrapper-video{
        position: absolute;
        left: -228px;
    }
}