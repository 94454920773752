
.bg-dark-1{
    background: #0E0E0E;
}

p{
    br{
        @media #{$md-layout} {
            display: none;
        }
        @media #{$sm-layout} {
            display: none;
        }
    }
}

.approch-area-left{
    .title-area-left-wrapper{
        .pre-title{
            padding: 7px 16px;
            border-radius: 4px;
            border: 1px solid #141414;
            color: #fff;
            margin-bottom: 20px;
            display: block;
            max-width: max-content;
        }
        .title{
            color: #fff;
            text-transform: uppercase;
            margin-top: 30px;
            font-weight: 700;
            font-size: 48px;
            line-height: 1.1;
            &:last-child{
                margin-top: 0;
            }
            @media #{$smlg-device} {
                font-size: 36px;
                line-height: 1.1;
            }
            @media #{$md-layout} {
                font-size: 36px !important;
                line-height: 1.1 !important;
            }
            @media #{$sm-layout} {
                font-size: 26px !important;
                line-height: 1.1 !important;
            }
            br{
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
    }
    p.disc{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #74787C;
    }
}
.our-approch-area-style-one{
    position: relative;
    z-index: 10;
    .grid-lines-wrapper{
        top: 0;
        .grid-lines{
            .grid-line{
                background: #0E0E0E;
            }
        }
    }
}
.single-approach-area-start{
    padding: 32px;
    background-color: #111111;
    border-radius: 6px;
    position: relative;
    margin-bottom: 20px;
    transition: .3s;
    overflow: hidden;
    z-index: 2;
    width: 519px;
    border: 1px solid #242323;
    @media #{$laptop-device} {
        width: 100%;
    }
    @media #{$smlg-device} {
        width: 100%;
    }
    @media #{$md-layout} {
        width: 100%;
    }
    @media #{$sm-layout} {
        width: 100%;
    }
    @media #{$small-mobile} {
        padding: 15px;
    }
    &.two{
        &::after{
            content: '02' !important;
        }
        &::before{
            background-image: url(../images/banner/24.jpg);
        }
    }
    &.three{
        &::after{
            content: '03' !important;
        }
        &::before{
            background-image: url(../images/banner/25.jpg);
        }
    }
    &::before{
        background-image: url(../images/banner/03.png);
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        width: 0%;
        height: 100%;
        z-index: -1;
        position: absolute;
        right: 0;
        top: 0;
        transition: .6s;
    }
    &:hover{
        background-color: none;
        .title{
            font-style: italic;
            &::after{
                width: 100%;
                left: 0;
            }
            &::before{
                font-size: 22px;
                right: -35px;
                opacity: 1;
            }
        }
        p.disc{
            color: #fff;
        }
        &::before{
            z-index: -1;
            width: 100%;
            left: 0;
            top: 0;
        }
        &::after{
            color: rgba(0, 0, 0, 0);
            -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #bdbdbd;
        }
    }
    &::after{
        position: absolute;
        content: '01';
        font-size: 80px;
        right: 20px;
        top: 30px;
        color: rgba(0, 0, 0, 0);
        -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #282828;
        font-weight: 700;
        transition: .3s;
    }
    .title{
        color: #fff;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 10px;
        transition: all .1s;
        position: relative;
        max-width: max-content;
        &::before{
            content: '\f061';
            font-family: 'fontawesome';
            position: absolute;
            right: -0px;
            font-weight: 300;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            transition: all .5s;
        }
        &::after{
            position: absolute;
            content: '';
            right: 0;
            bottom: 0;
            height: 1px;
            background: #fff;
            width: 0%;
            transition: .5s;
        }
    }
    p.disc{
        color: #74787C;
        br{
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
}



.main-wrapper-case-studies{
    .title{
        font-weight: 800;
        font-size: 200px;
        line-height: 210px;
        color: #fff;
        text-transform: uppercase;
        @media #{$laptop-device} {
            font-size: 180px;
        }
        @media #{$smlg-device} {
            font-size: 150px;
            @media #{$md-layout} {
                font-size: 120px;
            }
            @media #{$sm-layout} {
                font-size: 100px;
            }
        }
        span{
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #74787C;
            color: #0E0E0E;
        }
    }
}
.container-full-150{
    padding: 0 150px;
    @media #{$sm-layout} {
        padding: 0 15px;
    }
}
.flot-right-150{
  width: 110%;
  @media #{$large-mobile} {
    width: 100%;
  }
}


.carticle-slide-active{
    .title{ 
        @media #{$sm-layout }{
            color: #fff;
            font-weight: 700;
            font-size: 28px;
            text-transform: uppercase;
            line-height: 43px;
        }
    }
}












