
.rts-progress-area{
    .thumbnail{
        display: block;
        overflow: hidden;
        max-width: max-content;
        img{
            transition: .3s;
        }
        &:hover{
            img{
                transform: scale(.95);
            }
        }
    }
}

.single-progress-area-h4{
    margin-top: 30px;
    &:first-child{
        margin-top: 0;
    }
    .page-wrapper {
        width: calc(100% - 100px);
        margin: 100px auto 200px;
    }
    .progress {
        height: 5px;
        position: relative;
        margin-top: 14px;
        border-radius: 0;
        .bg--primary{
            background: var(--color-primary-m);
            border-radius: 0;
        }
    }
    .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
    .progress-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #ffffff;
            letter-spacing: 0.03em;
            text-transform: capitalize;
            margin-bottom: 0;
            font-family: var(--font-primary);
        }
        span{
            &.parcent{
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
            }
        }
    }
}

// circle progress

/*For CodePen*/
svg.radial-progress {
	height: auto;
	max-width: 240px;
	padding: 10px;
    border-radius: 50%;
    border: 1px solid rgba(31, 31, 33, 0.08);
	transform: rotate(-90deg);
	width: 100%;
	background-image: none;
	border-radius: 50%;
    transition: .3s;
	circle {
		fill: transparent;
		stroke: #fff;
	}
	circle.bar-static {
		stroke: #1E1E1E !important;
        stroke-width: 2px;
        transition: .3s;
	}
	circle.bar--animated {
		stroke-dasharray: 219.91148575129;
		stroke: #ffffff;
		stroke-dashoffset: 219.91148575129;
		stroke-width: 2px;
        transition: .3s;
		// stroke-linecap: round;
	}
	text {
		fill: #fafafa;
		font-size: 16px;
		font-weight: 700;
		text-anchor: middle;
        font-family: var(--font-primary);
        fill: rgb(245, 245, 245);
        transition: .3s;
	}
    // &:hover{
    //     circle.bar-static{
    //         stroke-width: 1px;
    //     }
    //     circle.bar--animated{
    //         stroke-width: 4px;
    //     }
    //     text{
    //         fill:#1F1F21;
    //     }
    // }
}
section.svg-container {
	svg.radial-progress {
		&:nth-child(even) {
			margin: 0 15px;
		}
	}
	&:nth-last-of-type(1) {
		svg.radial-progress {
			background: linear-gradient(260deg,#ff0300,#ffc900);
		}
	}
	&:nth-last-of-type(0) {
		svg.radial-progress {
			background-image: linear-gradient(60deg,#3d3393 0%,#2b76b9 37%,#2cacd1 65%,#35eb93 100%);
		}
	}
}

.devider {
	font-size: 50px;
	padding: 20px;
	min-height: 11vh;
    color: #fff;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(-225deg,#FF057C 0%,#FF057C 40%,#321575 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600;
	&:nth-last-of-type(2) {
		background-image: linear-gradient(260deg,#ff0300,#ffc900);
	}
}

.single-progress-circle{
    display: flex;
    justify-content: center;
    position: relative;
    .small-text{
        position: absolute;
        bottom: -30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        font-family: var(--font-primary);
        text-transform: uppercase;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
}






// extra css

.progress-ciurcle-wrapper-one{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$md-layout} {
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
    }
    @media #{$sm-layout} {
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
    }
}