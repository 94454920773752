// drop down

.nav-area{
    .navbar-nav-1{
        .menu-item{
            position: relative;
            .sub-menu{
                font-size: 14px;
                position: absolute;
                top: 103%;
                width: 200px;
                left: calc(50% - 50px);
                margin: 0;
                background-color: #323234;
                z-index: 5;
                z-index: 4;
                visibility: hidden;
                opacity: 0;
                -webkit-transform: translateY(10px) perspective(1px);
                transform: translateY(10px) perspective(1px);
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transition: visibility 250ms ease, opacity 250ms ease, -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                transition: visibility 250ms ease, opacity 250ms ease, -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                transition: visibility 250ms ease, opacity 250ms ease, transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                transition: visibility 250ms ease, opacity 250ms ease, transform 0.55s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                border-radius: var(--radius-lg);
                -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.05);
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.05);
                padding: 10px 0 !important;
                border-radius: 10px;
                min-width: max-content;
                &::after{
                    content: "";
                    position: absolute;
                    background-color: #323234;
                    border-radius: 0;
                    width: 16px;
                    height: 16px;
                    top: -8px;
                    left: calc(50% - 71px);
                    z-index: -1;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                li.menu-item{
                    width: 100%;
                    margin-left: 0;
                    border-radius: 0.25rem;
                    margin-left: 0 !important;
                    a{
                        line-height: 1.4;
                        font-size: 15px;
                        color: #fafaff !important;
                        padding: 14px 8px;
                        margin-left: 8pt;
                        margin-right: 8pt;
                        cursor: pointer;
                        border-radius: 4pt;
                        -webkit-transition: background-color 150ms ease;
                        transition: background-color 150ms ease;
                        display: block;
                        text-align: left;
                    }
                    .menu-link{
                        position: relative;
                        transition: all .4s;
                        &::before{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 0;
                            height: 1px;
                            background: #FFFFFF;
                            transition: all .4s;
                        }
                    }
                    &:hover{
                        .menu-link{
                            padding: 14px 0 14px 15px;
                            &::before{
                                width: 10px;
                            }
                        }
                    }
                }
                &.home-demos{
                    &::after{
                        @media #{$smlg-device} {
                            left: calc(33% - 83px);
                        }
                    }
                }
            }
            &.menu-item-has-children{
                &:hover{
                    & > .sub-menu{
                        visibility: visible;
                        -webkit-transform: translateZ(0) perspective(1px);
                        transform: translateZ(0) perspective(1px);
                        opacity: 1;
                        -webkit-transition: visibility 150ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                        transition: visibility 150ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                        transition: visibility 150ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1), transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                        transition: visibility 150ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1), transform 0.55s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                    }
                }
            }
        }
    }
}



.drop-down-rts{
    .menu-item-has-children{
        .sub-menu{
            li{
                a{
                    text-transform: capitalize !important;
                    font-size: 15px !important;
                }
            }
        }
    }
}


.nav-area .navbar-nav-1 .menu-item.main-nav-on{
    padding: 15px 0;
}

.rolling-text {
    display: inline-block;
    overflow: hidden;
    line-height: 14px;
    height: 14px;
    width: max-content;
  }
  
  .rolling-text.butn {
    padding: 0 35px;
  }
  
  .social-text .rolling-text {
    line-height: 26px;
    height: 26px;
  }
  
  .rolling-text:hover .letter,
  .rolling-text.play .letter {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  
  .rolling-text .letter {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    -o-transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1), -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }

  
.nav-area .navbar-nav-1 .menu-item .mega-menu-01.sub-menu{
    width: 650px !important;
    display: flex !important;
    align-items: flex-start !important;
    left: calc(50% - 255px) !important;
    padding: 20px !important;
    padding-top: 30px !important;
    @media #{$smlg-device} {
        left: calc(50% - 145px) !important;
    }
    .single-mega{
        margin: 0 !important;
        flex-basis: 33.33%;
        .tag{
            margin-left: 20px;
            font-size: 16px !important;
            text-transform: uppercase !important;
            font-weight: 500;
        }
        .main-wrapper{
            margin-top: 10px !important;
        }
    }
}
.nav-area .navbar-nav-1 .menu-item .mega-menu-01.sub-menu.elements{
    min-width: 1036px !important;
    display: flex !important;
    align-items: flex-start !important;
    left: calc(-172% - 387px) !important;
    padding: 20px !important;
    @media #{$smlg-device} {
        min-width: 903px !important;
        display: flex !important;
        align-items: flex-start !important;
        left: calc(-105% - 310px) !important;
        padding: 20px !important;
    
    }
    .single-mega{
        margin: 0 !important;
        flex-basis: 25%;
        padding-top: 14px;
        .tag{
            margin-left: 20px;
            font-size: 16px !important;
            text-transform: uppercase !important;
            font-weight: 500;
        }
        .main-wrapper{
            margin-top: 10px !important;
        }
    }
    &::after{
        left: calc(50% - -16px);
        @media #{$smlg-device} {
            left: calc(43% + 16px);
        }
    }
}

body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    border-radius: 0px;
    background: #fff;
}

body::-webkit-scrollbar-thumb {
    background: #000; 
    border-radius: 1px;
}

.nav-area .navbar-nav-1 .menu-item .mega-menu-01.sub-menu .single-mega .tag{
    position: relative;
    color: #A8A8A8;
    &::after{
        position: absolute;
        content: '';
        left: 0;
        width: 55px;
        height: 1px;
        background: #A8A8A8;
        bottom: -7px;
    }
}